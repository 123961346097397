<template>
    <div id="calendar-navigation" class="calendar-navigation" :class="[$mq]">
        <div class="calendar-top">
            <div class="calendar-btn" v-if="true" @click="show_calendar = !show_calendar"></div>
            <div id="days-navigation" class="days-navigation">
                <transition-group name="list" tag="div" class="days-container hide-scrollbar">
                    <div class="day" v-for="(day, index) in days" :key="index" @click="selectDay(day)" :class="[{ 'current-day': day.date == oCurrentDay.date }, { selected: oSelectedDate.date === day.date }]">
                        <div class="day-name">
                            {{ day.name }}
                        </div>
                        <div class="day-num">
                            {{ day.number }}
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <CollapseTransition>
            <div class="calendar-picker" v-if="show_calendar">
                <functional-calendar v-model="calendarDate" :newCurrentDate="new Date(oSelectedDate.date)" :dayNames="$t('calendar.day_names')" :monthNames="$t('calendar.months')" :shortMonthNames="$t('calendar.months')" @input="emitCalendar($event)" :isDatePicker="true" :date-format="'yyyy/mm/dd'" :change-month-function="true" ref="Calendar"> </functional-calendar>
            </div>
        </CollapseTransition>
    </div>
</template>
<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
export default {
    components: {
        CollapseTransition
    },
    props: {
        with_calendar: { type: Boolean, default: true },
        selected_date: { type: String, default: '' },
        future_days: { type: Number, default: 1 },
        past_days: { type: Number, default: 0 }
    },
    data() {
        return {
            selected_date_prop: '',
            Afuture_days: [],
            Apast_days: [],
            oCurrentDay: {},
            oSelectedDate: {},
            days: [],
            show_calendar: false,
            calendarDate: {}
        }
    },
    computed: {},
    filters: {},
    methods: {
        emitCalendar(param) {
            let newDay = moment(param.selectedDate)
            if (newDay.isValid() && newDay.format('YYYY-MM-DD') != moment(this.oSelectedDate.date).format('YYYY-MM-DD')) {
                let newSelectedDate = {
                    name: newDay.format('ddd').replace('.', ''),
                    number: newDay.format('DD'),
                    date: newDay.format('YYYY/MM/DD')
                }
                this.selectDay(newSelectedDate)
                this.show_calendar = false
            }
        },
        calculateFutureDays() {
            this.Afuture_days = []
            let dateAux = moment(this.selected_date_prop)
            for (let index = 0; index < this.future_days; index++) {
                let newDay = moment(dateAux).add(1, 'days')
                dateAux = newDay
                this.Afuture_days.push({
                    name: newDay.format('ddd').replace('.', ''),
                    number: newDay.format('DD'),
                    date: newDay.format('YYYY/MM/DD')
                })
            }
        },
        calculatePastDays() {
            this.Apast_days = []
            let dateAux = moment(this.selected_date_prop)
            for (let index = 0; index < this.past_days; index++) {
                let newDay = moment(dateAux).subtract(1, 'days')
                this.Apast_days.push({
                    name: newDay.format('ddd').replace('.', ''),
                    number: newDay.format('DD'),
                    date: newDay.format('YYYY/MM/DD')
                })
                dateAux = newDay
            }
            this.Apast_days = this.Apast_days.reverse()
        },
        moment() {
            return moment()
        },
        selectDay(newSelectedDay) {
            const oldSelectedDay = { ...this.oSelectedDate }
            this.selected_date_prop = newSelectedDay.date
            this.calculateFutureDays()
            this.calculateSelectedDate()
            this.calculatePastDays()
            this.joinArrays()
            if (oldSelectedDay.date != newSelectedDay.date) {
                this.$emit('select', this.selected_date_prop)
            }
        },
        calculateSelectedDate() {
            const selectedCurrentDay = moment(this.selected_date_prop)
            this.oSelectedDate = {
                name: selectedCurrentDay.format('ddd').replace('.', ''),
                number: selectedCurrentDay.format('DD'),
                date: selectedCurrentDay.format('YYYY/MM/DD')
            }
        },
        calculateCurrentDay() {
            const momentCurrentDay = moment()
            this.oCurrentDay = {
                name: momentCurrentDay.format('ddd').replace('.', ''),
                number: momentCurrentDay.format('DD'),
                date: momentCurrentDay.format('YYYY/MM/DD')
            }
        },
        joinArrays() {
            this.days = []
            this.days = this.Apast_days
            this.days.push(this.oSelectedDate)
            this.days = this.days.concat(this.Afuture_days)
        }
    },
    mounted() {
        this.selected_date_prop = this.selected_date
        this.calculateCurrentDay()
        this.calculateFutureDays()
        this.calculateSelectedDate()
        this.calculatePastDays()
        this.joinArrays()
    },
    updated() {
        let arrSelectedDate = this.oSelectedDate.date.split('/')
        let date = `${arrSelectedDate[0]}/${parseInt(arrSelectedDate[1])}/${parseInt(arrSelectedDate[2])}`
        this.calendarDate.selectedDate = date
        if (this.$refs.Calendar) {
            this.$refs.Calendar.input.selectedDate = date
        }
    },
    watch: {
        past_days: function (newValue, oldValue) {
            this.calculatePastDays()
            this.joinArrays()
        },
        future_days: function (newValue, oldValue) {
            this.calculateFutureDays()
            this.joinArrays()
        },
        show_calendar: function (newValue, oldValue) {
            // Know if the calendar is visible
            this.$emit('calendarOpen', newValue)
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$main-box-color: #ede7db;
#calendar-navigation {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    height: 100%;
    .calendar-top {
        @include display-flex();
        width: 100%;
        height: 100%;
        .calendar-btn {
            @include background($image: img('input_datetime_neutro_s50.svg'), $color: #fff, $size: 30px);
            @include border-radius(5px);
            width: 50px;
            height: 50px;
            margin-right: 5px;
            min-width: 50px;
            cursor: pointer;
        }
        #days-navigation {
            width: calc(100% - 55px);
            // .list-item {
            //     display: inline-block;
            //     margin-right: 10px;
            // }
            // .list-enter-active,
            // .list-leave-active {
            //     transition: all 1s;
            // }
            // .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
            //     opacity: 0;
            //     transform: translateY(30px);
            // }
            .days-container {
                @include display-flex();
                width: 100%;
                height: 100%;
                gap: 5px;
                overflow-x: auto;
                .day {
                    @include display-flex();
                    @include flex-direction(column);
                    @include border-radius(5px);
                    @include justify-content();
                    @include align-items(center);
                    background-color: $main-box-color;
                    width: 100%;
                    height: 50px;
                    cursor: pointer;
                    .day-name {
                        @include font-size(xs);
                        font-family: $text-light;
                        color: $inactive-s80;
                        text-transform: uppercase;
                    }
                    .day-num {
                        @include font-size(m);
                        font-family: $text-light;
                        color: $inactive-s80;
                        text-transform: uppercase;
                        padding: 0px 22px;
                        padding-bottom: 1px;
                    }
                    &.current-day {
                        background-color: $main-t80;
                        .day-name {
                            @include font-size(xs);
                            font-family: $text-sbold;
                            color: $inactive-s80;
                            text-transform: uppercase;
                        }
                        .day-num {
                            @include font-size(m);
                            font-family: $text-bold;
                            color: $inactive-s80;
                            text-transform: uppercase;
                        }
                    }
                    &.selected {
                        background-color: $main;
                        .day-name {
                            @include font-size(xs);
                            font-family: $text-sbold;
                            color: #fff;
                            text-transform: uppercase;
                        }
                        .day-num {
                            @include font-size(m);
                            font-family: $text-bold;
                            color: #fff;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
    .calendar-picker {
        width: 100%;
        @include align-self(flex-start);
        height: 100%;
        padding-top: 10px;
        max-width: 500px;
    }
}
</style>
// PORTRAIT STYLES
<style lang="scss">
#calendar-navigation.portrait {
    .calendar-picker {
        // padding: 10px 10px 0;
        width: 100%;
        max-width: 500px;
    }
}
</style>
